/* Colors
Light green #8EAF74
Medium green #6B8C50  6b8b51
Dark green #465b35 
Blue #3E80C1
olive	#808000	rgb(128,128,0)
darkolivegreen	#556B2F	rgb(85,107,47)
olivedrab	#6B8E23	rgb(107,142,35)
font black #212529
*/
h2 {font-size:1.75rem;}
h3 {font-size:1.5rem;}
h4 {font-size:1.25rem;}
h5 {font-size:1rem;}
h6 {font-size:.75rem;}

.App {
  font-size: .8rem;
  font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, apple-system, BlinkMacSystemFont, "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear;  */
  /*height: 10vmin; */
 /* pointer-events: none; */
 margin: 3vmin 0vmin 2vmin 1vmin; 
}

.fjlogo {
  margin:0vmin 0vmin 2vmin 0vmin;
}

.App-header {
  background-color:#465b35;
  /* min-height: 100vh; */
  /* max-height: 24vmin; */
  display: flex;
  /* flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  color: white;
  position: relative;
  z-index: 200;
 
}


.App-link {
  color: #3E80C1;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.bodybkg {
  background-image: url(bodybkg.png);
  background-repeat: repeat-x;
  /* min-height:70vh; */
}

footer {
  background-color:#465b35;
  color:white;
  min-height:40vh;
  margin-top:6vmin;
}
a:link, a:visited, a:hover, a:active {text-decoration: none;}
footer a {color:white!important; text-decoration: none;}

.m-t-6 {margin-top:6vmin;}
.m-t-8 {margin-top:8vmin;}
.pf-m-t-6 {margin-top:4vmin;}
.m-t-4 {margin-top:4vmin;}
.m-t-22 {margin-top:22px;}
.pf-m-t-10 {margin-top:10vmin;}
.m-t-15 {margin-top:-15vmin;}
.m-b-0 {margin-bottom:0;}
.m-b-4 {margin-bottom:4vmin;}
.mw-200 {max-width:200px; margin:0px 15px;}

nav#Menu {margin-top:8.3vmin; margin-left:-15px; margin-bottom:2vmin!important; max-width:210px; z-index:200; position: relative;}
.nav-link {padding:.3rem 1rem!important; }
nav a {background-color:#000; color:white; margin-bottom:.5rem; }
nav a:hover {background-color:#3E80C1; color:white;}
.nav-pills .nav-link {border-radius: 0rem .5rem .5rem 0rem!important; box-shadow: 3px 3px 3px rgba(0,0,0, .5);}
.nav-pills .nav-link.active {background-color:#222!important; opacity: 0.5 !important; cursor: not-allowed;}

.figure-caption {text-align:center;}

.fade-appear,.fade-enter {
  opacity:0;
  z-index:1;
  transform: translateX(150%); 
  -webkit-transform: translateX(150%); 
}
.fade-enter-active, .fade-enter.fade-enter-active {
  opacity:1; 
  transform: translateX(0%); 
  -webkit-transform: translateX(0%); 
  transition: all 700ms ease-in-out 100ms;  
}
.fade-exit {
  opacity:1;
  /* transform: translateX(0%); */
}
.fade-exit.fade-exit-active {
  opacity:0;
  /* transition: opacity 2000ms; */
  /* transform: translateX(150%);
  transition: all 100ms ease-in-out;  */
}

.lowerright {
  position: absolute;
  right: 15px;
  bottom: 22px;
}

.upperright {
  position: absolute;
  right: 15px;
  top: 22px;
}
@media (max-width: 1600px) {
  .m-t-15 {margin-top:-12vmin;}
}
@media (max-width: 1490px) {
  .m-t-15 {margin-top:-8vmin;}
}

@media (max-width: 1300px) {
  .m-t-15 {margin-top:-2vmin;}
}

@media (max-width: 992px) { 
  .m-t-15 {margin-top:0vmin;}
 }

 @media (max-width: 575px) { 
  .pf-m-t-6 {margin-top:0vmin;}
  .pf-m-t-10 {margin-top:0vmin;}
  }
@media (max-width: 767px) { 
  .pf-m-t-6 {margin-top:0vmin;}
  .pf-m-t-10 {margin-top:0vmin;}
  .fjlogo {
    margin:0vmin 0vmin 2vmin 0vmin;
  }
  nav#Hamburger{
    position: absolute;
    right: 0;
    bottom: 15px;}
  nav#Menu {
    margin-top:1.6vmin; 
    margin-left:-15px;
    max-width:100%; 
    height:260px; 
    position: relative;
    z-index:100;}
  nav#Menu a {width:100%;}  
  #Menu.closed {
    opacity:0;
    height:0;
    overflow:hidden;
    transform: translateY(-100%);
    transition: all 0.3s ease; 
  }
  
  #Menu.open {
    transform: translateY(4%);
    transition: transform 0.3s ease;
  }
}
