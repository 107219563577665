.slidecontainer {
    margin-top:4vmin;
}
.slider {
    position:relative; 
    top:0; 
    left:0; 
    height:20vh; 
}
.slide { 
    position:absolute; 
    top:0; 
    left:0; 
    width:100%; 
    height:100%; 
    opacity:0;
    transition: 1500ms ease-in-out;   
} 

.starter { 
    position:absolute; 
    top:0; 
    left:0; 
    width:100%; 
    height:100%; 
    opacity:1;   
} 
/* .slide.topleft {transition:3s ease;}
.slide.topcenter {transition:6s ease;}
.slide.topright {transition:9s ease;}
.slide.bottomleft {transition:12s ease;}
.slide.bottomcenter {transition:15s ease;}
.slide.bottomright {transition:18s ease;} */

.slide.active {opacity:1; transition-duration: 2000ms ease-out;}
.image {
    position: absolute; 
    top:0; 
    left:0; 
    width:100%; 
    height:100%; 
    object-fit: cover;
}

.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }