#Person .card-columns {column-count:5;}
.card-img-top {
    object-fit: cover;
  }
  
  
  .smallcard .card-body {padding: .25rem!important;}
  .smallcard h5 {font-size:.6rem;}
  .smallcard h6 {display:none;}

  
    #Committee .card-img-top {
      height: 100%;
    }
    .smallcard .card-img-top {
      height: 5vw;
    }


  @media (min-width: 768px) { 
    .card-img-top {
      height: 11vw;
    }
    }
  @media (max-width: 767px) { 
    .card-columns {
      column-count: 2;
    }
    #Committee .card {display:inline-block!important;}
    #Person .card {display:none;}
    #Person .card-columns {column-count:2;}
    #IndPerson.card {width:100%;}
    #IndPerson .card-img-top, #Committee .card-img-top {height:100%;}
    .card-img-top {height:25vw;}
    .smallcard .card-img-top {
       height:100%; 
    }
    h5.card-title {font-size:.8rem;}
}