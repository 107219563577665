@import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

.image-gallery-slides {
    /* max-height:60vh; */
}

.image-gallery-slide img {
    object-position: center;
    object-fit: cover;
}

.image-gallery-slide .image-gallery-description { 
    /* opacity:0; */
    bottom:auto; 
    top: 0;
    width:100%;
}

/* .image-gallery-slide:hover .image-gallery-description {
    opacity: 1;
    transition: .5s ease-in-out;
} */
